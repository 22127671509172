/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'reply-all-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.021 11.9L3.453 8.62a.72.72 0 010-1.238L8.021 4.1a.716.716 0 011.079.619V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z"/><path pid="1" d="M5.232 4.293a.5.5 0 01-.106.7L1.114 7.945l-.042.028a.147.147 0 000 .252l.042.028 4.012 2.954a.5.5 0 11-.593.805L.539 9.073a1.147 1.147 0 010-1.946l3.994-2.94a.5.5 0 01.699.106"/>',
    },
});
